import React, { Component } from 'react';
import { Form, FormGroup, Col , FormControl, Button} from 'react-bootstrap';


class Comment extends Component {

    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
 
        this.state = {
          message: '',
          prenom: '',
          email: ''
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      onChange(e){
          this.setState({
            message: e.target.value
            //TODO : fonction générique pour obtenir le nom de la variable
            //TODO : intégrer email
          });
      }
      onChangeEmail(e){
        this.setState({
          email: e.target.value
          //TODO : fonction générique pour obtenir le nom de la variable
          //TODO : intégrer email
        });
    }

      handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        
        let dbCon = this.props.db.database().ref('/messages');
        dbCon.push({
          message: data.get('message'),
          email: data.get('email'),
          isValidated: 0
        });
        this.setState({
          message: '',
          email: ''
        });
      }

  render() {
    return (
        <Col sm={6} smOffset={3}>
        <h3>Laissez un message</h3>
        <Form horizontal onSubmit={this.handleSubmit}>

        <FormGroup bsSize="large" controlId="formHorizontaltextarea">
            <Col sm={12}>
            <FormControl required componentClass="textarea" name='message'  placeholder="Message" onChange={this.onChange}
                    value={this.state.message}/>
            </Col>
        </FormGroup>

        <FormGroup bsSize="large" >
            <Col sm={12}>
            <FormControl required type="email" name='email'  placeholder="Entrez votre email" onChange={this.onChangeEmail}
                    value={this.state.email}/>
            </Col>
        </FormGroup>

        <FormGroup>
            <Col sm={12}>
            <Button type="submit">Envoyer</Button>
            </Col>
        </FormGroup>
        </Form>
        <br/>
        </Col>
    );
  }
}

export default Comment;
