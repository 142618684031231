import React, { Component } from 'react';
import './App.css';
import { Navbar } from 'react-bootstrap';
import Welcome from './welcome';
import Comment from './Comment';
import ModalGoogleDrive from './Modal-GoogleDrive'
import MessageList from './MessageList'
import firebase from 'firebase/app';
import axios from 'axios'
import 'firebase/database';



class App extends Component {

  constructor(props){
    super(props);
    var config = {
      apiKey: "AIzaSyA7DBBzVHhqAqYKyY2tQiKpvgyRlcWj5nA",
      authDomain: "aurelienaboudarham-69792.firebaseapp.com",
      databaseURL: "https://aurelienaboudarham-69792.firebaseio.com",
      projectId: "aurelienaboudarham-69792",
      storageBucket: "aurelienaboudarham-69792.appspot.com",
      messagingSenderId: "1044890407860"
    };
    firebase.initializeApp(config);
  }

  componentDidMount() {
    axios.get(`https://api.ipify.org/?format=json`)
      .then(res => {
        var date = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year
        var hours = new Date().getHours(); //Current Hours
        var min = new Date().getMinutes(); //Current Minutes
        var sec = new Date().getSeconds(); //Current Seconds
        let dbCon = firebase.database().ref('/userinfo');
        dbCon.push({
          informations : {'data': res.data, 'savedDate': date + '/' + month + '/' + year + ' ' + hours + ':' + min + ':' + sec,}
        });
      });
  }

  render() {
    return (
      <div className="App">
      <div className="Welcome">
        <Navbar fixedTop className="Navbar">
          <Navbar.Header>
            <Navbar.Brand className="Navbar-brand">
              Aurélien Aboudarham
            </Navbar.Brand>
          </Navbar.Header>
        </Navbar>
        <Welcome/>
    </div>
      <ModalGoogleDrive />
    <MessageList db={firebase} />
    <Comment db={firebase} /> 
    </div>
    );
  }
}

export default App;
