import React from 'react'
import aurelien from './img/aurelien.jpg';
import img2 from './img/2.jpg';
import img3 from './img/3.jpg';
import img4 from './img/4.jpg';
import img5 from './img/5.jpg';
import img6 from './img/6.jpg';
import img7 from './img/7.jpg';
import img8 from './img/8.jpg';
import img9 from './img/9.jpg';
import img10 from './img/10.jpg';
import img11 from './img/11.jpg';
import img12 from './img/12.jpg';
import { Carousel } from 'react-bootstrap';


const Welcome = () => (

 <div className="container">
<div >
     <Carousel className="peopleCarouselImg">
  <Carousel.Item>
    <img width={900} height={500} alt="900x500" src={aurelien} className="img-responsive center-block"/>
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img2} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img3} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img4} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img5} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img6} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img7} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img8} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img9} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img10} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img11} />
  </Carousel.Item>
  <Carousel.Item>
  <img width={900} height={500} alt="900x500" src={img12} />
  </Carousel.Item>
</Carousel>
</div>
  <br />
<blockquote>
  <p><q>À notre soleil de tous les jours... Notre ange parti trop tôt !</q></p>
  <footer>La famille</footer>
</blockquote>
</div>




)

export default Welcome