import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';

class ModaleGoogleDrive extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
  
      this.state = {
        show: false
      };
    }
  
    handleClose() {
      this.setState({ show: false });
    }
  
    handleShow() {
      this.setState({ show: true });
    }
  
    render() {

      return (
        <div>
            <h3>Images et vidéos</h3>
          <Button bsStyle="default" bsSize="small" onClick={this.handleShow}>
            Déposer une image / vidéo
          </Button>
          <br />
          <Col sm={6} smOffset={3}>
          <iframe title="Google folder" id="google-iframe" width="70%" frameBorder="0" height="400px" src="https://drive.google.com/embeddedfolderview?id=18furifFa3Ch286aPjndoxpm7IbgFOisB#grid" ></iframe>
          </Col>
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
            Déposer un fichier
            </Modal.Header>
            <Modal.Body>
            <iframe title="Google upload"  id="google-upload-iframe"width="100%" height="320px" frameBorder="0" src="https://script.google.com/macros/s/AKfycbzRcMWxZQhZ1npThDQI1vmAjr7Nfl9r_A7UCM2HkVaxah655Kk/exec" ></iframe>
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
  
  export default ModaleGoogleDrive;


  